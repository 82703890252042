import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Helmet from 'react-helmet';

import Footer from '../components/Footer';
import Header from '../components/Header';
import logo from '../components/logo.svg';
import useFeaturedContent from '../static_queries/useFeaturedContent';
import indexStyles from './index.module.scss';
import vyneLogo from './vyne-logo-round.svg';

export default function IndexPage() {
    const featuredContent = useFeaturedContent();
    return (
        <div>
            <Helmet>
                <html lang="en" />
                <title>Notional</title>
                <meta name="description" content='Software engineering · Digital Transformation · Technology strategy' />
            </Helmet>
            <Header title='Notional' />

            <section className={`${indexStyles.contentSection} ${indexStyles.hero}`}>
                <img src={logo} className={indexStyles.heroLogo + " is-hidden-mobile"} alt="Notional logo" />
                <div className={`${indexStyles.tagline}`}>
                    <div class="is-heading-font">Innovation delivered.</div>
                    <div class="is-heading-font">Grow your ideas from Viable to Valuable</div>
                    <div className={indexStyles.categoryHeading}>
                        <hr class="" />
                        <span>
                            Software engineering · Digital Transformation · Technology strategy
                        </span>
                    </div>
                </div>
            </section>
            <section className={`${indexStyles.contentSection} ${indexStyles.section1}`}>
                <h4>Our work</h4>
                <p className={indexStyles.strong}>We're Notional - a technology consultancy.</p>
                <p>We help companies of all sizes achieve an edge, through rapid delivery of groundbreaking ideas.
                </p>
            </section>
            <section className={`${indexStyles.section2}`}>
                <div className={indexStyles.contentBox}>
                    <img class="is-hidden-mobile"
                        src={'https://static.wixstatic.com/media/nsplsh_4643486c59765235674a49~mv2_d_6000_4000_s_4_2.jpg/v1/fill/w_984,h_654,al_c,q_85,usm_0.66_1.00_0.01/Image%20by%20Danial%20RiCaRoS.webp'}
                        alt='Man at a computer' />
                    <div>
                        <div className={`${indexStyles.categoryHeading} ${indexStyles.contentBoxCategoryHeader}`}>
                            <hr />
                            <span>Technology Strategy</span>
                        </div>
                        <h3>Transformation delivered in days, not weeks.</h3>
                        <p>See how we helped our client reduce their product development time by <strong>80%</strong>,
                            and
                            successfully
                            deliver a previously unviable project.</p>
                        <p>Using Semantic Integration, Notional connected six independent trading platforms in record
                            time, delivering a sophisticated routing engine and configuration platform, that replaced
                            manual trade entry with automated STP</p>
                        <div className={indexStyles.callToActionContainer}>
                            <a className={indexStyles.callToAction} href='/blog/trade-routing-engine'>Read the case study</a>
                        </div>
                    </div>

                </div>
                <div className={indexStyles.contentBoxHero + ` is-hidden-mobile`}>
                    80% faster build time
                </div>
            </section>

            <section className={`${indexStyles.contentSection} ${indexStyles.section3}`}>
                <div className={indexStyles.section3Content}>
                    <div className={`${indexStyles.section3Header}`}>
                        <img src={vyneLogo} alt='Vyne logo' className={`${indexStyles.smallLogo}`} />
                        <h4>
                            Vyne - AI powered integration</h4>
                    </div>
 
                    <div className={indexStyles.section3Text}>
                        <p>Companies spend too much time & money integrating internal platforms. </p>
                        <p>We built Vyne to enable systems to connect, collaborate, and adapt autonomously, without
                            requiring manual integration code.</p>
                        <p>This allows businesses to focus their effort on building, rather than plumbing.</p>
                    </div>
                    <a href='https://vyne.co' className={indexStyles.callToAction}>Find out more</a>
                </div>
                <img src={vyneLogo} alt='Vyne logo' className={`${indexStyles.largeLogo} is-hidden-mobile`} />
            </section>

            <section className={`${indexStyles.contentSection} ${indexStyles.section4}`}>
                <div>
                    <h4>Case Studies</h4>
                    <h3>Some of our previous success stories</h3>
                </div>
                <div className={indexStyles.blogList}>
                    {featuredContent
                        .map(blog => {
                            return (
                                <Link to={`/blog/${blog.node.fields.slug}`} key={blog.node.id}>
                                    <div className={indexStyles.blogListEntryHero}>
                                        <Img
                                            fluid={
                                                blog.node.frontmatter.hero_image.childImageSharp.fluid
                                            }
                                            alt={blog.node.frontmatter.title}
                                        />
                                    </div>
                                    <div>
                                        <h3>{blog.node.frontmatter.title}</h3>
                                        <p>{blog.node.frontmatter.subtitle}</p>
                                    </div>
                                </Link>
                            )
                        })}
                </div>
            </section>

            <Footer />
        </div>
    )
}