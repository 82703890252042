import {graphql, useStaticQuery} from "gatsby"

export default function useFeaturedContent() {
    const data = useStaticQuery(graphql`
query getFeaturedContent {
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, 
    filter: {frontmatter: {featured_content: {eq: true}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          subtitle
          hero_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}

  `)
    return data.allMarkdownRemark.edges
}